<template>
  <div>
    <h3>Your private key is:</h3>
    <div class="code-view">
      <pre class="c">OFKey:{{getKey}}</pre>
    </div>
    <span class="text-muted">Paste this in our Telegram bot</span>
    <br/>
    <br/>
    <md-button href="http://t.me/OF_image_bot" target="_blank" class="md-raised md-primary">
      <md-icon>telegram</md-icon>
      Open Telegram BOT (@OF_image_bot)
    </md-button>
    <br/>
    <br/>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'loading',
  components: {
  },
  data() {
    return {
      percentage: 0,
      showDialog: false
    }
  },
  mounted() {

  },
  computed: {
    getKey() {
      return this.$route.params.userId;
    }
  },
  methods: {
  }
}

</script>
<style lang="scss">
html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .inner-container {
    text-align: center;
  }
}

.code-view {
  padding: 10px;
  background-color: #e3e3e3;
  .c {
    overflow-x: scroll;
  }
}
</style>

